@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

body {
  user-select: none;

  overflow-y: scroll;
  overflow-x: hidden;
}

* {
  /* outline: 1px solid red; */
}

:root {
  font-family: "Montserrat", sans-serif !important;
}

.monterrat {
  font-family: "Montserrat", sans-serif !important;
}

.shimmer {
  display:inline-block;
  -webkit-mask: linear-gradient(-60deg,#ffdc17 30%,rgba(251, 255, 0, 0.233),#f0e556 70%) right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
}

@keyframes shimmer {
  100% {-webkit-mask-position:left}
}

body::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: #444;
  border-radius: 10px;
}

body::-webkit-scrollbar-track {
  background: #212022;
}

body::-webkit-scrollbar-thumb:hover {
  background: #333;
}